import React from "react"
import styled from "styled-components"
import { device } from "../utils/devices"

const StyledWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`

const StlyedText = styled.span`
  font-size: 12px;
  @media ${device.tablet} { 
    font-size: 16px;
  }
  
  margin-left: 25px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
  &:after {
    position: absolute;
    content: '';
    height: 1px;
    width: 100%;
    bottom: -5px;
    left: 0;
    background: black;
  }
`


export const MoreLink = ({ text }) => (
  <>
    <StyledWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="11">
        <path
          d="M22.745.564L31.52 5.5l-8.775 4.936-.49-.872L28.59 6H0V5h28.59l-6.335-3.564.49-.872z"/>
      </svg>
      <StlyedText>{text}</StlyedText>
    </StyledWrapper>
  </>
)


MoreLink.defaultProps = {
  text: "Mehr",
}
