import React from "react"
import { Services } from "../components/services"
import { Columns } from "../components/columns"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Headline, StyledHeadlineWrapper } from "../components/headline"
import SEO from "../components/seo"
import { Contact } from "../components/contact"
import SingleImage from "../components/singleImage"


export default ({ data }) => {
  return (
    <Layout>
      <SEO title={data.wpPage.title}/>
      <div className={"container"}>
        <div className={"row justify-content-center"}>
          <div className={"col-10"}>
            <StyledHeadlineWrapper>
              <Headline
                headlineH1={data.wpPage.acfPageContent.headline}
                thinSubline={data.wpPage.acfPageContent.subline}
              />
            </StyledHeadlineWrapper>
          </div>
        </div>
      </div>

      <SingleImage image={data.wpPage.featuredImage.node.localFile}/>

      <div className={"container"}>
        <div className={"row justify-content-center"}>
          <div className={"col-10"}>
            <Columns content={data.wpPage.acfPageContent.content}/>
          </div>
        </div>
      </div>
      <Services/>
      <Contact/>
    </Layout>
  )
}


export const query = graphql`
    query ($slug: String!) {
        wpPage(slug: {eq: $slug}) {
            title
            content
            link
            slug
            acfPageContent {
                style
                content
                headline
                subline
            }
            featuredImage {
                node {
                    localFile {
                        childImageSharp {
                            fluid(
                                maxWidth: 1200,
                                maxHeight: 800,
                                quality: 90,
                                cropFocus: CENTER
                            ) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }
`

