import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { device } from "../utils/devices"

const StyledColumns = styled.div`
 margin-bottom: 100px;
 @media ${device.laptop}{
    columns: 2 auto;
    column-gap: 32px;
 }
  @media ${device.desktop} {
     column-gap: 80px;
  }
`

export const Columns = ({ content }) => (
  <StyledColumns dangerouslySetInnerHTML={{ __html: content }}/>
)

Columns.propTypes = {
  content: PropTypes.string,
}
