import Img from "gatsby-image"
import React from "react"
import styled from "styled-components"

const StyledSingleImage = styled.div`
  margin-bottom: 50px;
  margin-top: 50px;
`

const SingleImage = ({image}) => (
  image &&
  <StyledSingleImage className={"container"}>
    <div className={"row justify-content-center"}>
      <div className={"col-12"}>
        <Img fluid={image.childImageSharp.fluid}/>
      </div>
    </div>
  </StyledSingleImage>
)

export default SingleImage
