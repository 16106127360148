import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import Img from "gatsby-image"

import styled from "styled-components"
import { Headline } from "./headline"
import { device } from "../utils/devices"
import { MoreLink } from "./moreLink"


const StyledServicesText = styled.div`
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      padding: 30px;      
      min-height: calc(100% - 140px);
      width: 100%;
      @media ${device.tablet} {
        padding: 50px;  
      }
  `

const StyledServicesTextWrapper = styled.div`
      height: 100%;
      display: flex;
      z-index: 1;
      align-items: center;
      @media ${device.tablet}{
          position: absolute;
      }
`

const StyledImageWrapper = styled.div`
    transform: translate3d(0, 40px, 0);
    @media ${device.tablet}{
        transform: translate3d(0, 0, 0);
    }    
`

const StyledServicesItem = styled(Link)`
    margin-bottom: 100px;
    position: relative;
    &:nth-child(even) {    
      ${StyledImageWrapper} {
       margin-left: 0;
      }
    
      ${StyledServicesTextWrapper} {
       right: 0;
      }
    }
  `

export const Services = ({ parent }) => {
  const data = useStaticQuery(graphql`
      query {
          allWpAcfLeistung(sort: {fields: menuOrder}) {
              nodes {
                  title
                  id
                  slug
                  link
                  wpParent {
                      node {
                          slug
                      }
                  }
                  content
                  excerpt
                  featuredImage {
                      node {
                          localFile {
                              childImageSharp {
                                  fluid(
                                      maxWidth: 600,
                                      maxHeight: 400,
                                      quality: 80,
                                      cropFocus: CENTER
                                  ) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  `)

  return (
    <div className={"container"}>
      <div className={"services row justify-content-center"}>
        {
          data.allWpAcfLeistung.nodes.map((item, index) => {
              let shouldRender = item.wpParent === null
              let parentSlug = item.wpParent ? item.wpParent.node.slug : undefined
              let url = item.link

              if (parent !== undefined) {
                shouldRender = item.wpParent !== null && parentSlug === parent
              }

              if (shouldRender) {
                return (
                  <StyledServicesItem key={index}
                                      to={url}
                                      className={"services__item col-12 col-lg-10 col-xl-8"}>
                    <div className={"row"}>
                      <StyledImageWrapper className={"col-12 offset-md-2 col-md-8 offset-md-4"}>

                        {item.featuredImage.node && item.featuredImage.node.localFile &&
                        <Img className={"box-shadow"} fluid={item.featuredImage.node.localFile.childImageSharp.fluid}
                             sizes={{ ...item.featuredImage.node.localFile.childImageSharp.fluid, aspectRatio: 3 / 2 }}/>
                        }

                      </StyledImageWrapper>
                      <StyledServicesTextWrapper className={"col-10 offset-1 offset-md-0 col-md-6 col-lg-5"}>
                        <StyledServicesText>
                          <Headline
                            title={item.title}
                          />

                          <div dangerouslySetInnerHTML={{ __html: item.excerpt }}/>
                          <MoreLink text={"mehr"}/>
                        </StyledServicesText>
                      </StyledServicesTextWrapper>
                    </div>
                  </StyledServicesItem>
                )
              }
              return false
            },
          )
        }
      </div>
    </div>
  )
}

